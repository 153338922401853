import { ReactNode } from 'react';

import CreditCardIcon from '@mui/icons-material/CreditCard';

import { PaymentMethodsEnum } from '@/types';
import { EntryPointEnum, OrderMethodsEnum, OrderStatusEnum, PaymentStatusEnum } from '@/store/slices/order/types';

export type PaymentMethod = Record<
  PaymentMethodsEnum,
  {
    title: string;
    logo: ReactNode;
  }
>;

export type OrderMethod = Record<
  OrderMethodsEnum,
  {
    title: string;
    logo: ReactNode;
  }
>;

export const paymentMethodMapping: PaymentMethod = {
  [PaymentMethodsEnum.Cash]: {
    title: 'Cash',
    logo: <CreditCardIcon />,
  },
  [PaymentMethodsEnum.CreditCard]: {
    title: 'Credit Card',
    logo: <CreditCardIcon />,
  },
  [PaymentMethodsEnum.Idram]: {
    title: 'Idram',
    logo: <CreditCardIcon />,
  },
  [PaymentMethodsEnum.Glovo]: {
    title: 'Glovo',
    logo: <CreditCardIcon />,
  },
  [PaymentMethodsEnum.YandexEats]: {
    title: 'Yandex eats',
    logo: <CreditCardIcon />,
  },
};

export const orderMethodMapping: OrderMethod = {
  [OrderMethodsEnum.DineIn]: {
    title: 'Dine In',
    logo: null,
  },
  [OrderMethodsEnum.Glovo]: {
    title: 'Glovo',
    logo: null,
  },
  [OrderMethodsEnum.YandexEats]: {
    title: 'Yandex Eats',
    logo: null,
  },
  [OrderMethodsEnum.TakeAway]: {
    title: 'Take Away',
    logo: null,
  },
  [OrderMethodsEnum.CallDelivery]: {
    title: 'Delivery',
    logo: null,
  },
  [OrderMethodsEnum.AppDelivery]: {
    title: 'App Delivery',
    logo: null,
  },
};

export const paymentStatusMapping = {
  [PaymentStatusEnum.Pending]: {
    title: 'Pending',
    logo: null,
  },
  [PaymentStatusEnum.Success]: {
    title: 'Success',
    logo: null,
  },
  [PaymentStatusEnum.Fail]: {
    title: 'Fail',
    logo: null,
  },
};

export const OrderStatusMapping = {
  [OrderStatusEnum.Pending]: {
    title: 'Pending',
    logo: null,
  },
  [OrderStatusEnum.Succeeded]: {
    title: 'Success',
    logo: null,
  },
  [OrderStatusEnum.Cancelled]: {
    title: 'Cancelled',
    logo: null,
  },
};

export const EntryPointMapping = {
  [EntryPointEnum.Pos]: {
    title: 'Pos',
    logo: null,
  },
  [EntryPointEnum.Application]: {
    title: 'Application',
    logo: null,
  },
};
